import React from "react";

import Logo from "../assets/images/logo-02.png";

const Home = () => {
  return (
    <section className="home">
      <div className="home__hero">
        <div className="home__header">
          <div className="container">
            <div className="home__logo">
              <img src={Logo} alt="Dreambet" />
            </div>
          </div>
        </div>
        <div className="home__content-wrapper">
          <div className="container">
            <div className="home__content-container">
              <p className="home__content-text">We're almost there</p>
              <h1 className="home__heading-large">
                Coming <br />
                soon
              </h1>
              <p className="home__content-para">
                We're bringing you a groundbreaking betting experience,
                available right at your fingertips via{" "}
              </p>
              <p className="home__content-para">
                SMS and USSD. Whether you're on the go or relaxing at home,
                you'll be able to
              </p>
              <p className="home__content-para">
                place bets on your favorite sports quickly, easily, and securely
              </p>
              <div className="u-margin-bottom-small">
                <p className="home__content-para">
                  Stay tuned for more updates, and be the first to experience
                  the convenience, speed, and
                </p>
                <p className="home__content-para">
                  excitement of our platform. Sign up now to be notified when we
                  go live
                </p>
              </div>
              <label className="home__label"> Email </label>
              <div className="home__content-email">
                <div className="home__input-wrapper">
                  <input className="home__input" type="text" />
                  <button className="home__input-btn">Sign up</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Home;
